//Migrated
<template>
  <div class="flex pb-12 justify-center items-center flex-wrap">
    <fa-layers class="fa-5x fa-fw">
      <fa
        :icon="icons.faCloudSun"
        transform="up-13 right-17"
        style="--fa-secondary-color: #fecb21"
      />
      <fa
        :icon="icons.faUmbrellaBeach"
        transform="grow-12"
        opacity
        style="--fa-secondary-color: #fecb21"
      />
    </fa-layers>
    <div class="flex items-center">
      <div
        class="px-12"
        :class="{ 'error__content': !maxWidth }"
      >
        <slot>
          <h3
            class="text-center"
            data-i18n="errorTitle"
          >
            {{ $t('errorTitle') }}
          </h3>
          <p data-i18n="serverError">
            {{ $t('serverError') }}
          </p>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { faCloudSun, faUmbrellaBeach } from '@fortawesome/pro-duotone-svg-icons'

export default defineNuxtComponent({
  props: {
    maxWidth: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      icons: {
        faCloudSun,
        faUmbrellaBeach,
      },
    }
  },
})
</script>

<style type="scss" scoped>
.fa-fw {
  width: 4em;
  height: 3em;
}

.error__content {
  max-width: 20rem;
}
</style>
